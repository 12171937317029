<template>
    <div class="Profile">
        <div class="container">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="company-profile-ruleForm">
                <el-form-item label="公司名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="座右铭" prop="slog">
                    <el-input v-model="ruleForm.slog"></el-input>
                </el-form-item>
                <el-form-item label="座机" prop="phone">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="cellphone">
                    <el-input v-model="ruleForm.cellphone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item label="公司地址" prop="address">
                    <el-input v-model="ruleForm.address"></el-input>
                </el-form-item>
                <el-form-item label="公司简介背景图片(建议分辨率1440*650)" prop="image">
                    <el-upload
                            class="company-profile-uploader"
                            :with-credentials="true"
                            :action="action"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="company-profile-pic">
                        <i v-else class="el-icon-plus company-profile-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="联系我们页面背景图片(建议分辨率1440*650)" prop="connect_image">
                    <el-upload
                            class="company-profile-uploader"
                            :with-credentials="true"
                            :action="action"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess2"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl2" :src="imageUrl2" class="company-profile-pic">
                        <i v-else class="el-icon-plus company-profile-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="服务时间" prop="Server_time">
                    <el-input v-model="ruleForm.Server_time"></el-input>
                </el-form-item>

                <el-form-item label="首页物联网跳转背景图片(建议分辨率1440*650)" prop="firstbackground">
                    <el-upload
                            class="company-profile-uploader"
                            :with-credentials="true"
                            :action="action"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess3"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl3" :src="imageUrl3" class="company-profile-pic">
                        <i v-else class="el-icon-plus company-profile-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="产品中心页面背景图片(建议分辨率1440*400)" prop="productbackground">
                    <el-upload
                            class="company-profile-uploader"
                            :with-credentials="true"
                            :action="action"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess4"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl4" :src="imageUrl4" class="company-profile-pic">
                        <i v-else class="el-icon-plus company-profile-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="解决方案页面背景图片(建议分辨率1440*400)" prop="solutionbackground">
                    <el-upload
                            class="company-profile-uploader"
                            :with-credentials="true"
                            :action="action"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess5"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl5" :src="imageUrl5" class="company-profile-pic">
                        <i v-else class="el-icon-plus company-profile-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" v-loading.fullscreen.lock="fullscreenLoading">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Profile",
        data(){
            return{
                fullscreenLoading: false,//loading框
                action:'/api/carousel/test',
                imageUrl:'',    //公司简介背景图片
                imageUrl2:'',    //联系我们背景图片
                imageUrl3:'',    //首页物联网背景图片
                imageUrl4:'',    //产品中心背景图片
                imageUrl5:'',    //解决方案背景图片
                ruleForm: {},   //表单数据
                flag:false,     //是否修改公司背景
                flag2:false,     //是否修改联系我们页面背景
                flag3:false,     //是否修改首页物联网跳转图片
                flag4:false,     //是否修改产品中心背景图片
                flag5:false,     //是否修改解决方案背景图片
                rules: {
                    name: [
                        { required: true, message: '请输入公司名称', trigger: 'blur' },
                        { min: 3, max: 18, message: '长度在 3 到 18 个字符', trigger: 'blur' }
                    ],
                    slog: [
                        { required: true, message: '请输入公司口号', trigger: 'blur' },
                        { min: 3, max: 18, message: '长度在 3 到 18 个字符', trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, message: '请填写座机号码', trigger: 'blur' }
                    ],
                    cellphone: [
                        { required: false, message: '请填写手机号码', trigger: 'blur' }
                    ],
                    email: [
                        { required: true, message: '请填写邮箱地址', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请填写公司地址', trigger: 'blur' }
                    ],
                    image:[
                        { required: true, message: '请选择公司简介页面背景图片', trigger: 'blur' }
                    ],
                    connect_image:[
                        { required: true, message: '请选择联系我们页面背景图片', trigger: 'blur' }
                    ],
                    Server_time:[
                        { required: true, message: '请填写服务时间', trigger: 'blur' }
                    ],
                    firstbackground:[
                        { required: true, message: '请选择首页方案跳转背景图片', trigger: 'blur' }
                    ],
                    productbackground:[
                        { required: true, message: '请选择产品中心页面背景图片', trigger: 'blur' }
                    ],
                    solutionbackground:[
                        { required: true, message: '请选择解决方案页面背景图片', trigger: 'blur' }
                    ],
                },
            }
        },
        created(){
            this.getData();
        },
        methods:{
            //获取数据
            async getData(){
                this.fullscreenLoading = true;
                try{
                    const {data} = await this.$axios({
                        url:'/api/admincompany/showall',
                    });
                    this.fullscreenLoading = false;
                    if(data.code){
                        this.ruleForm = data.data;
                        this.imageUrl = this.$store.getters.getPicBaseUrl + data.data.image;
                        this.imageUrl2 = this.$store.getters.getPicBaseUrl + data.data.connect_image;
                        this.imageUrl3 = this.$store.getters.getPicBaseUrl + data.data.firstbackground;
                        this.imageUrl4 = this.$store.getters.getPicBaseUrl + data.data.productbackground;
                        this.imageUrl5 = this.$store.getters.getPicBaseUrl + data.data.solutionbackground;
                    }else{
                        this.$message.error(data.msg);
                    }
                }catch (e) {
                    this.fullscreenLoading = false;
                    this.$message.error('网络错误，请稍后重试');
                    console.log(e);
                }
            },
            //点击保存
            async submitForm(formName) {
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        try {
                            const {data} = await this.$axios({
                                method:'POST',
                                url:'/api/admincompany/update',
                                data:{
                                    name:this.ruleForm.name,
                                    email:this.ruleForm.email,
                                    image:this.ruleForm.image,
                                    address:this.ruleForm.address,
                                    phone:this.ruleForm.phone,
                                    cellphone:this.ruleForm.cellphone,
                                    Server_time:this.ruleForm.Server_time,
                                    image_status:this.flag?1:0,
                                    connect_image:this.ruleForm.connect_image,
                                    connect_image_status:this.flag2?1:0,
                                    slog:this.ruleForm.slog,
                                    firstbackground:this.ruleForm.firstbackground,
                                    firstbackground_status:this.flag3?1:0,
                                    productbackground:this.ruleForm.productbackground,
                                    productbackground_status:this.flag4?1:0,
                                    solutionbackground:this.ruleForm.solutionbackground,
                                    solutionbackground_status:this.flag5?1:0,
                                },
                            });
                            this.fullscreenLoading = false;
                            if(data.code){
                                this.$message({
                                    message: data.msg,
                                    type: 'success',
                                });
                                this.flag = false;
                                this.flag2 = false;
                                this.flag3 = false;
                                this.flag4 = false;
                                this.flag5 = false;
                            }else{
                                this.$message.error(data.msg);
                            }
                        }catch (e) {
                            this.fullscreenLoading = false;
                            this.$message.error('网络错误，请稍后重试');
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //公司页面背景上传成功执行
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                this.ruleForm.image = res.file_name;
                this.flag = true;
            },
            //联系我们页面背景上传成功执行
            handleAvatarSuccess2(res, file) {
                this.imageUrl2 = URL.createObjectURL(file.raw);
                this.ruleForm.connect_image = res.file_name;
                this.flag2 = true;
            },
            //首页方案跳转背景上传成功执行
            handleAvatarSuccess3(res, file) {
                this.imageUrl3 = URL.createObjectURL(file.raw);
                this.ruleForm.firstbackground = res.file_name;
                this.flag3 = true;
            },
            //产品中心页面背景上传成功执行
            handleAvatarSuccess4(res, file) {
                this.imageUrl4 = URL.createObjectURL(file.raw);
                this.ruleForm.productbackground = res.file_name;
                this.flag4 = true;
            },
            //解决方案页面背景上传成功执行
            handleAvatarSuccess5(res, file) {
                this.imageUrl5 = URL.createObjectURL(file.raw);
                this.ruleForm.solutionbackground = res.file_name;
                this.flag5 = true;
            },
            //上传前校验
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt5120K = file.size / 1024 < 5120;

                if (!isJPG && !isPNG) {
                    this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
                }
                if (!isLt5120K) {
                    this.$message.error('上传图片大小不能超过 5 Mb!');
                }
                return (isJPG||isPNG) && isLt5120K;
            }
        },
    }
</script>

<style>
    .company-profile-uploader .el-upload{
        /*height: auto;*/
    }
</style>

<style scoped>
    .company-profile-pic{
        width: 100%;
        height: -webkit-fill-available;
    }
</style>